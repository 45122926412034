import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import { apiData, localData } from '../config/siteConfig'
import { Link } from 'gatsby'

const NavMenu = () => {
   const menu = (apiData && apiData.length > 0 ? apiData : localData.siteMenu)
   const [isMenuOpen, setIsMenuOpen] = useState(false)
   return (
      <div>
         <div className='hamburger-menu' onClick={() => setIsMenuOpen(prev => !prev)}>
            <div className="menu-line" />
            <div className="menu-line" />
         </div>
         <div className={`${isMenuOpen ? '' : 'hidden'} fixed inset-0 bg-black z-50`}>
            <div className='h-9'>
               <XMarkIcon width={40} className='ml-auto cursor-pointer' onClick={() => setIsMenuOpen(prev => !prev)} />
            </div>
            <div>
               <ul className='isolation-auto'>
                  {menu.map((item, i) => (
                     <li key={i} className='relative text-center border w-full hover-box overflow-hidden'>
                        <Link to={'#' + item.toLowerCase()} className='text-[80px] text-white'>{item}</Link>
                     </li>
                  ))}
               </ul>
            </div>
            <div className='h-9' />
         </div>
      </div>
   )
}

export default NavMenu
