import { user1, user2, user3, post1, post2, glow, demo1, demo2, home, testimonialBg, client1, client2, client3, client4, client5 } from "../images";


export const apiData = []
export const localData = {
   logotype: '',
   siteMenu: ['Home','Sobre','Servicos', 'Projectos', 'contacto'],
   headerSection: {
    title: 'PLAY',
    subTitle: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
    description: '',
   },
   about: [
      {
         _id: '1',
         thumbnail: [glow],
         title: 'Aplicativos avançados de Inteligência Artificial para as necessidades do seu negócio.',
         subTitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus amet tempora, unde laborum cumque esse distinctio! Quas eos cum commodi iste adipisci sequi dolor officiis minus architecto eveniet. Laboriosam, placeat.',
         description: ''
      },
      {
         _id: '2',
         thumbnail: [user1, user2, user3],
         title: 'Desbloqueie a capacidade máxima de sucesso do seu negócio.',
         subTitle: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus amet tempora, unde laborum cumque esse distinctio! Quas eos cum commodi iste adipisci sequi dolor officiis minus architecto eveniet. Laboriosam, placeat.',
         description: 'Agende agora sua ligação inicial com nossa equipe de empréstimos.'
      },
   ],
   services: [
      {
         _id: '1',
         thumbnail: [demo1],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: '',
      },
      {
         _id: '2',
         thumbnail: [demo2],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: '',
      },
      {
         _id: '3',
         thumbnail: [user3],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: '',
      },
      {
         _id: '4',
         thumbnail: [home],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: '',
      },
      {
         _id: '5',
         thumbnail: [testimonialBg],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: '',
      },
      {
         _id: '6',
         thumbnail: [user2],
         title: 'Crequalme criative Go',
         subTitle: 'Criar & Otimizar',
         description: '',
      },
   ],
   portfolio: [
      {
         _id: '1',
         thumbnail: [client1],
         title: 'Projectos',
         subTitle: '',
         description: '',
         url: '',
      },
      {
         _id: '2',
         thumbnail: [client2],
         title: 'Communication between studio departments',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '2',
      },
      {
         _id: '3',
         thumbnail: [client3],
         title: 'Designers who changed the web with Webflow',
         subTitle: 'Criar & Otimizar',
         description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi debitis suscipit dicta, excepturi hic tempore reiciendis deserunt quisquam dolorum veniam enim consequatur pariatur modi ut sunt rerum ad ea dolore.',
         url: '3',
      },
      {
         _id: '4',
         thumbnail: [client4],
         title: 'Leve sua startup para o próximo nível.',
         subTitle: '',
         description: '',
         url: '',
      },
      {
         _id: '5',
         thumbnail: [client5],
         title: 'Leve sua startup para o próximo nível.',
         subTitle: '',
         description: '',
         url: '',
      },
      {
         _id: '6',
         thumbnail: [post2],
         title: 'Leve sua startup para o próximo nível.',
         subTitle: '',
         description: '',
         url: '',
      },
      {
         _id: '7',
         thumbnail: [post2],
         title: 'Leve sua startup para o próximo nível.',
         subTitle: '',
         description: '',
         url: '',
      },
      {
         _id: '8',
         thumbnail: [post2],
         title: 'Leve sua startup para o próximo nível.',
         subTitle: '',
         description: '',
         url: '',
      },
   ],
   testimonial: [
      {
         _id: '1',
         photo: client1,
         name: 'Jonh doe',
         position: '',
         message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus corrupti quibusdam commodi doloribus debitis magnam ullam exercitationem at cum! Nostrum omnis suscipit necessitatibus eius architecto eum voluptates consequuntur perspiciatis commodi.',
      },
      {
         _id: '2',
         photo: client2,
         name: 'Jonh doe',
         position: '',
         message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus corrupti quibusdam commodi doloribus debitis magnam ullam exercitationem at cum! Nostrum omnis suscipit necessitatibus eius architecto eum voluptates consequuntur perspiciatis commodi.',
      },
      {
         _id: '3',
         photo: client3,
         name: 'Jonh doe',
         position: '',
         message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus corrupti quibusdam commodi doloribus debitis magnam ullam exercitationem at cum! Nostrum omnis suscipit necessitatibus eius architecto eum voluptates consequuntur perspiciatis commodi.',
      },
      {
         _id: '4',
         photo: client4,
         name: 'Jonh doe',
         position: '',
         message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus corrupti quibusdam commodi doloribus debitis magnam ullam exercitationem at cum! Nostrum omnis suscipit necessitatibus eius architecto eum voluptates consequuntur perspiciatis commodi.',
      },
   ],
   contact: {
      phone: [''],
      whatsapp: 'https://web.whatsapp.com/',
      facebook: 'https://web.facebook.com',
      twitter: '/',
      linkedIn: '/',
      instagram: '/',
      email: [],
      youtube: '/',
      address: '/',
    },

}
