import React, { ReactNode } from 'react'
import NavBar from './shared/NavBar'

interface ILayout {
   children: ReactNode
}

const Layout = ({ children }: ILayout) => {
   return (
      <>
         <NavBar />
         <main>
            {children}
         </main>
      </>
   )
}

export default Layout