import React, { useState } from 'react'
import { Link } from 'gatsby'
import { motion, useScroll, useMotionValueEvent } from 'framer-motion'
import { apiData, localData } from '../../config/siteConfig'
import NavMenu from '../NavMenu'
import { instagram, likedin, x } from '../../images'
import { IContact } from '../../interfaces/data'

const Navbar = () => {

   const media = (apiData && apiData.length > 0 ? apiData : localData.contact) as IContact
   const mediaIcon = [
      { link: media.twitter, icon: x },
      { link: media.instagram, icon: instagram },
      { link: media.facebook, icon: likedin }
   ]
   const { scrollY } = useScroll()
   const [hidden, setHidden] = useState(false)
   useMotionValueEvent(scrollY, 'change', (latest) => {
      const previous = scrollY.getPrevious()
      if (latest > previous && latest > 150) {
         setHidden(true)
      } else {
         setHidden(false)
      }
   })
   return (
      <>
         <motion.header className={`fixed w-full z-20 h-[73px] pb-3 rounded-md ${hidden ? 'bg-transparent' : ''}`} variants={{ visible: { y: 0 }, hidden: { y: '-200%' } }} animate={hidden ? 'hidden' : 'visible'}>
            <nav className='py-2 px-8 flex items-center justify-between gap-6 navbar'>
               <div className='text-white font-bold flex-between gap-4 relative z-10'>
                  {mediaIcon.map((item, i) => (
                     <Link to='/' key={i} className='icon-hover'>
                        <img src={item.icon} width={20} height={20} alt="" />
                     </Link>
                  ))}
               </div>
               <NavMenu />
            </nav>
         </motion.header>

      </>
   )
}

export default Navbar